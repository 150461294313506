
import axios from "./http";

/**
 * 认证接口
 * @param params {code: string, pid: string}
 * @returns {Promise<AxiosResponse<any>>}
 */
export function auth(params) {
   return axios.get("/wxmp/auth/login", {
        params,
    })
}
