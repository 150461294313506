<template>
  <div class="page">腾讯认证中...</div>
</template>
<script>

import { auth } from "@/api/auth";
import { mapMutations } from "vuex";
import { SET_USER, SET_TOKEN } from "../const.js";
// import wx from 'jweixin-npm'

export default {
  data() {
    return {
    };
  },
  mounted() {
    console.info("auth2 url code",this.getQueryString("code"),this.getQueryString("pid"))
    if (this.getQueryString("code")){
      this.updateBandingAndGetInfo(this.getQueryString("code"))
    }else{
      let u = encodeURIComponent('https://mp.xinian727.com/auth')
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx4c7008d8d3a7213d&redirect_uri='+u+'&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
    }

  },
  methods: {
    ...mapMutations([SET_USER, SET_TOKEN]),
    async updateBandingAndGetInfo(code) {
      let d = await auth({ code: code,pid: undefined });
      if (d.notRegister){
        // wx.config({
        //   // debug: true, // 调试时可开启
        //   appId: '小程序 AppID', // <!-- replace -->
        //   timestamp: 0, // 必填，填任意数字即可
        //   nonceStr: 'nonceStr', // 必填，填任意非空字符串即可
        //   signature: 'signature', // 必填，填任意非空字符串即可
        //   jsApiList: ['chooseImage'], // 必填，随意一个接口即可
        //   openTagList:['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
        // })
        //跳转到小程序
        console.info("跳转到小程序")
      }else {
        let _this = this;
        setTimeout(function() {
          _this.setToken(d.token);
          _this.setUser(JSON.stringify(d.userInfo));
          _this.$router.push("/");
        }, 700);

      }
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      return null;
    },
  },
  computed: {},
};
</script>

<style lang="less" scoped>
</style>

<style>
html {
  background: #ffffff;
}
</style>
